import { Flag } from "@alch/dx-entities";
import { Link } from "@alch/ui";
import { Grid01, Status } from "@alch/ui/icons/16";
import {
  formatCompactNumberLong,
  formatWholeDollars,
} from "@features/payment/Checkout/lib/numberFormatters";
import PlanSelectionCard from "@features/signup/Pages/PlanPage/PlanSelectionCard";
import SignupTemplatePage from "@features/signup/Pages/SignupTemplatePage";
import { useSignupFlowFormData } from "@features/signup/SignupFlowProvider";
import IconText from "@features/signup/components/IconText";
import {
  SignupFormFieldName,
  SignupSchemaField,
  SignupStep,
} from "@features/signup/constants";
import { planFormSchema } from "@features/signup/schemas";
import useAvailablePlanTiers from "@hooks/useAvailablePlanTiers";
import { useFlag } from "@hooks/useFlag";
import { useIsPAYGTierActive } from "@hooks/useIsPAYGTierActive";
import AmbireWallet from "@static/signup/powering-teams-logos/ambire-wallet.svg";
import Aperture from "@static/signup/powering-teams-logos/aperture.svg";
import DappRadar from "@static/signup/powering-teams-logos/dapp-radar.svg";
import Earnifi from "@static/signup/powering-teams-logos/earnifi.svg";
import InkFinance from "@static/signup/powering-teams-logos/ink-finance.svg";
import Lido from "@static/signup/powering-teams-logos/lido.svg";
import OpenSea from "@static/signup/powering-teams-logos/open-sea.svg";
import VoxelX from "@static/signup/powering-teams-logos/voxel-x.svg";
import { PlanTerm, SubscriptionTier } from "@util/constants";
import {
  PAID_SELF_SERVICE_TIERS,
  PricingTier,
  TIER_PRICING,
} from "@util/payment/pricingConfig";
import { planTierToPricingTier } from "@util/payment/tierMappings";
import { Url } from "@util/urls";
import clsx from "clsx";
import { omit } from "lodash";
import { useEffect, useMemo, useState } from "react";

const CURRENT_STEP = SignupStep.Plan;

export interface PlanSelection {
  tier: SubscriptionTier;
  subtitle: string;
  monthlyPrice: string;
  yearMonthlyPrice?: string;
  valueProps: React.ReactNode[];
  poweringTeamsLogos: string[];
}

export const usePlans = (): PlanSelection[] => {
  const isPAYGTierActive = useIsPAYGTierActive();

  if (isPAYGTierActive.isPending) return [];

  return [
    {
      tier: SubscriptionTier.FREE,
      subtitle: "The largest & most powerful free tier in web3",
      monthlyPrice: formatWholeDollars(TIER_PRICING[PricingTier.FREE].baseCost),
      valueProps: [
        <IconText
          key={"cu-per-month"}
          Icon={Status}
          text={`${isPAYGTierActive.data ? "100M" : formatCompactNumberLong(TIER_PRICING[PricingTier.FREE].includedUnits)} CUs per month`}
        />,
        <IconText
          key={"num-apps"}
          Icon={Grid01}
          text={`${TIER_PRICING[PricingTier.FREE].numOfApps} apps`}
        />,
      ],
      poweringTeamsLogos: [InkFinance, VoxelX],
    },
    {
      tier: SubscriptionTier.PAYG,
      subtitle: "Pay only what you use, use more save more",
      monthlyPrice: `Starting at ${formatWholeDollars(1)}`,
      valueProps: [
        <IconText
          key={"cu-per-month"}
          Icon={Status}
          text="Pay as you go for CUs"
        />,
        <IconText
          key={"num-apps"}
          Icon={Grid01}
          text={`${TIER_PRICING[PricingTier.PAYG].numOfApps} apps`}
        />,
      ],
      poweringTeamsLogos: [DappRadar, Earnifi],
    },
    {
      tier: SubscriptionTier.GROWTH,
      subtitle: "For teams that need more capacity to grow",
      monthlyPrice: formatWholeDollars(
        TIER_PRICING[PricingTier.GROWTH].baseCost,
      ),
      valueProps: [
        <IconText
          key={"cu-per-month"}
          Icon={Status}
          text={`${formatCompactNumberLong(TIER_PRICING[PricingTier.GROWTH].includedUnits)} CUs per month`}
        />,
        <IconText
          key={"num-apps"}
          Icon={Grid01}
          text={`${TIER_PRICING[PricingTier.GROWTH].numOfApps} apps`}
        />,
      ],
      poweringTeamsLogos: [AmbireWallet, DappRadar],
    },
    {
      tier: SubscriptionTier.SCALE,
      subtitle: "Enterprise discounts, no salesperson required",
      monthlyPrice: formatWholeDollars(
        TIER_PRICING[PricingTier.SCALE_MONTHLY].baseCost,
      ),
      yearMonthlyPrice: formatWholeDollars(
        TIER_PRICING[PricingTier.SCALE_ANNUAL].baseCost,
      ),
      valueProps: [
        <IconText
          key={"cu-per-month"}
          Icon={Status}
          text={`${formatCompactNumberLong(TIER_PRICING[PricingTier.SCALE_MONTHLY].includedUnits)} CUs per month`}
        />,
        <IconText
          key={"num-apps"}
          Icon={Grid01}
          text={`${TIER_PRICING[PricingTier.SCALE_MONTHLY].numOfApps} apps`}
        />,
      ],
      poweringTeamsLogos: [Aperture, Earnifi],
    },
    {
      tier: SubscriptionTier.ENTERPRISE,
      subtitle: "Volume pricing, unmatched support, custom SLAs",
      monthlyPrice: "Custom",
      valueProps: [
        <IconText key={"cu-per-month"} Icon={Status} text="Custom CUs" />,
        <IconText key={"num-apps"} Icon={Grid01} text="Unlimited" />,
      ],
      poweringTeamsLogos: [Lido, OpenSea],
    },
  ];
};

const PlanPage = () => {
  const { formData, setFormData } = useSignupFlowFormData();
  const plans = usePlans();
  const availablePlanTiers = useAvailablePlanTiers();
  const disablePlanChangeFlag = useFlag(Flag.DisablePlanChange);

  const disabledPlanTiers = useMemo(
    () =>
      disablePlanChangeFlag.isPending
        ? undefined
        : disablePlanChangeFlag.data
          ? availablePlanTiers.data?.filter((tier) =>
              PAID_SELF_SERVICE_TIERS.includes(
                planTierToPricingTier(tier, PlanTerm.MONTHLY),
              ),
            )
          : [],
    [
      availablePlanTiers.data,
      disablePlanChangeFlag.data,
      disablePlanChangeFlag.isPending,
    ],
  );

  const [planSelected, setPlanSelected] = useState<SubscriptionTier | null>(
    formData?.plan?.planType ?? null,
  );
  const [planTermSelected, setPlanTermSelected] = useState<PlanTerm>(
    formData?.plan?.planTerm ?? PlanTerm.ANNUAL,
  );

  const plansContainerClassName = clsx([
    "grid w-[96vw] max-w-[1400px] grid-cols-1 items-end gap-x-6 gap-y-7",
    "signup-sm:grid-cols-2 signup-lg:auto-cols-fr signup-lg:grid-flow-col",
  ]);

  const subtitle = (
    <div className="flex">
      <span>Not sure which plan is best for you?&nbsp;</span>
      <Link href={Url.AlchemyPricing} target="_blank">
        Compare Plans
      </Link>
    </div>
  );

  const handleSubmit = () => {
    const result = planFormSchema.safeParse({
      [SignupFormFieldName.PlanType]: planSelected,
      // All non-scale plans have a monthly term
      [SignupFormFieldName.PlanTerm]:
        planSelected === SubscriptionTier.SCALE
          ? planTermSelected
          : PlanTerm.MONTHLY,
    });

    if (result.success) {
      setFormData((existingFormData) => {
        return {
          // Remove enterprise details if user selects a different plan
          ...(planSelected !== SubscriptionTier.ENTERPRISE
            ? omit(existingFormData, [SignupSchemaField.EnterpriseDetails])
            : existingFormData),
          [SignupSchemaField.Plan]: result.data,
        };
      });
    }
  };

  useEffect(() => {
    if (availablePlanTiers.data && disabledPlanTiers) {
      setPlanSelected((selected) => {
        if (selected != null) return selected;

        if (!disabledPlanTiers.includes(availablePlanTiers.data[1])) {
          return availablePlanTiers.data[1];
        }

        return (
          availablePlanTiers.data.find(
            (tier) => !disabledPlanTiers.includes(tier),
          ) ?? null
        );
      });
    }
  }, [disabledPlanTiers, availablePlanTiers.data]);

  return (
    <SignupTemplatePage
      title="Select your plan"
      subtitle={subtitle}
      currentStep={CURRENT_STEP}
      onNextButtonClick={handleSubmit}
      disableNextButton={planSelected === null}
    >
      <div className={plansContainerClassName}>
        {plans
          .filter((plan) => availablePlanTiers.data?.includes(plan.tier))
          .map((plan) => (
            <PlanSelectionCard
              key={plan.tier}
              plan={plan}
              isSelected={plan.tier === planSelected}
              onClick={() => {
                setPlanSelected(plan.tier);
              }}
              onPlanTermSelected={(planTerm: PlanTerm) => {
                setPlanTermSelected(planTerm);
              }}
              planTermSelected={planTermSelected}
              disabled={disabledPlanTiers?.includes(plan.tier) ?? true}
            />
          ))}
      </div>
    </SignupTemplatePage>
  );
};

export default PlanPage;
