import { PricingTier } from "@util/payment/pricingConfig.ts";
import clsx from "clsx";
import GraphBar from "./PricingPickerGraphBar";
import PricingPickerDashedLine from "./images/pricing-picker-dashed-line.svg?react";
import estimatedMonthlyTierCosts from "./lib/estimatedMonthlyTierCosts";

export const PICKER_TIERS = [
  PricingTier.GROWTH,
  PricingTier.SCALE_MONTHLY,
  PricingTier.SCALE_ANNUAL,
  PricingTier.PAYG,
  PricingTier.ENTERPRISE,
] as const;

export const TIER_LABELS = {
  [PricingTier.FREE]: "Free",
  [PricingTier.GROWTH]: "Growth",
  [PricingTier.SCALE_MONTHLY]: "Scale / mo",
  [PricingTier.SCALE_ANNUAL]: "Scale / yr",
  [PricingTier.PAYG]: "Pay As You Go",
  [PricingTier.ENTERPRISE]: "Enterprise",
  [PricingTier.OTHER]: "Enterprise Trial",
};

const GraphBarLabel = (props: {
  tier: PricingTier;
  deselected?: boolean;
  onClick: (tier: PricingTier) => void;
}) => (
  <div
    className={clsx(
      "cursor-pointer rounded-lg bg-grayscale-100 py-3 text-center text-sm font-bold leading-none",
      {
        "text-blue-600": !props.deselected,
        "text-grayscale-700": props.deselected,
      },
    )}
    onClick={() => props.onClick(props.tier)}
  >
    {TIER_LABELS[props.tier]}
  </div>
);

const PricingPickerGraph = (props: {
  usage: number;
  currentTier: PricingTier;
  previewTier: PricingTier;
  onChangePreviewTier: (tier: PricingTier) => void;
  availableTiers: PricingTier[];
}) => {
  const tierCosts = estimatedMonthlyTierCosts(props.usage);
  const maxCost = Math.max(
    ...props.availableTiers.map((tier) => tierCosts[tier]),
  );
  const currentTierCost = tierCosts[props.currentTier];

  return (
    <div className="w-full">
      <div className="relative mb-3 flex h-64 flex-col-reverse">
        <PricingPickerDashedLine className="absolute top-1/2 w-full" />
        <PricingPickerDashedLine className="absolute top-0 w-full" />
        <div className="z-10 grid h-full auto-cols-fr grid-flow-col gap-3 border-b border-grayscale-400 pt-1">
          {props.availableTiers.map((tier) => (
            <GraphBar
              key={tier}
              tier={tier}
              cost={tierCosts[tier]}
              maxCost={maxCost}
              isCurrentTier={props.currentTier === tier}
              currentTierCost={currentTierCost}
              onChangePreviewTier={props.onChangePreviewTier}
              deselected={props.previewTier !== tier}
            />
          ))}
        </div>
      </div>
      <div className="mb-3 grid auto-cols-fr grid-flow-col gap-3">
        {props.availableTiers.map((tier) => (
          <GraphBarLabel
            key={tier}
            tier={tier}
            deselected={props.previewTier !== tier}
            onClick={props.onChangePreviewTier}
          />
        ))}{" "}
      </div>
    </div>
  );
};

export default PricingPickerGraph;
