import { PricingTier } from "@util/payment/pricingConfig.ts";
import clsx from "clsx";

const GraphBarInterior = (props: {
  cost: number;
  maxCost: number;
  deselected?: boolean;
  isCurrentTier?: boolean;
  currentTierCost: number;
}) => {
  const priceText = Intl.NumberFormat(undefined, {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  }).format(props.cost);

  const heightPercent =
    props.maxCost > 0 ? (props.cost / props.maxCost) * 100 : 0;
  const savePercent =
    props.currentTierCost > 0
      ? (1 - props.cost / props.currentTierCost) * 100
      : 0;

  return (
    <>
      {heightPercent > 0 && (
        <div
          className={clsx(
            "flex shrink grow items-center justify-center bg-white text-xs font-semibold",
            {
              "text-blue-600": !props.deselected,
              "text-grayscale-950": props.deselected,
            },
          )}
          style={{ flexBasis: `${100 - heightPercent}%` }}
        >
          {savePercent > 0 && (
            <span>
              Save&nbsp;
              {Intl.NumberFormat(undefined, {
                maximumFractionDigits: 0,
                style: "percent",
              }).format(savePercent / 100)}
            </span>
          )}
        </div>
      )}
      <div
        className={clsx(
          "flex shrink grow flex-col-reverse items-center justify-start",
          {
            "bg-blue-600": !props.deselected,
            "bg-grayscale-950": props.deselected,
          },
        )}
        style={{ flexBasis: `${heightPercent}%` }}
      >
        <div className="p-3 text-sm font-bold text-white">{priceText}/mo</div>
      </div>
    </>
  );
};

const GraphBarEnterpriseInterior = (props: { deselected?: boolean }) => (
  <>
    <div
      className={clsx(
        "flex shrink grow items-center justify-center bg-white text-center text-sm font-semibold",
        {
          "text-blue-600": !props.deselected,
          "text-grayscale-950": props.deselected,
        },
      )}
    >
      Contact us
    </div>
    <div
      className={clsx("h-2", {
        "bg-blue-600": !props.deselected,
        "bg-grayscale-950": props.deselected,
      })}
    />
  </>
);

const PricingPickerGraphBar = (props: {
  tier: PricingTier;
  cost: number;
  maxCost: number;
  currentTierCost: number;
  isCurrentTier?: boolean;
  deselected?: boolean;
  onChangePreviewTier: (tier: PricingTier) => void;
}) => {
  return (
    <div
      className="flex cursor-pointer flex-col"
      onClick={() => props.onChangePreviewTier(props.tier)}
    >
      <div
        className={clsx(
          "my-2 w-fit self-center p-2 text-center text-xs font-semibold leading-3",
          "bg-clip-text text-transparent",
          "rounded-lg border-2 border-blue-700",
        )}
        style={{
          backgroundImage:
            "linear-gradient(120deg, #5498FF 26.44%, #A131F9 109.11%)",
          visibility: props.isCurrentTier ? "visible" : "hidden",
        }}
      >
        Active
        <span className="hidden md:inline"> plan</span>
      </div>
      <div
        className={clsx("flex flex-1 flex-col border-2", {
          "border-blue-600": !props.deselected,
          "border-grayscale-950": props.deselected,
        })}
      >
        {props.tier !== PricingTier.ENTERPRISE ? (
          <GraphBarInterior
            cost={props.cost}
            maxCost={props.maxCost}
            deselected={props.deselected}
            isCurrentTier={props.isCurrentTier}
            currentTierCost={props.currentTierCost}
          />
        ) : (
          <GraphBarEnterpriseInterior deselected={props.deselected} />
        )}
      </div>
    </div>
  );
};

export default PricingPickerGraphBar;
