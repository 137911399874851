import { Checkbox } from "@alch/ui";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import clsx from "clsx";
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form";

export type PillToggleItem<TValue> = {
  label: string;
  value: TValue;
  Icon?: React.ReactNode;
};

interface PillToggleGroupProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  items: PillToggleItem<TFieldValues[TName]>[];
  maxSelectableItems?: number;
}

const PillToggleGroup = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  items,
  control,
  name,
  maxSelectableItems = 5,
}: PillToggleGroupProps<TFieldValues, TName>) => {
  const {
    field: { onChange, value: toggledValues },
  } = useController({
    control,
    name,
  });

  return (
    <ToggleGroup.Root
      type="multiple"
      onValueChange={onChange}
      value={toggledValues}
      className="grid grid-cols-2 gap-x-6 gap-y-4"
    >
      {items.map(({ value, label, Icon }) => {
        const isValueSelected = (
          toggledValues as TFieldValues[TName][]
        ).includes(value);
        const shouldDisableItem =
          toggledValues.length >= maxSelectableItems &&
          !(toggledValues as TFieldValues[TName][]).includes(value);

        return (
          <ToggleGroup.Item
            value={value}
            key={value}
            className={clsx(
              "flex h-[64px] items-center rounded-xl border-2 border-grayscale-300 bg-white p-[14px]",
              "aria-pressed:border-blue-400",
              shouldDisableItem && "cursor-not-allowed",
            )}
            disabled={shouldDisableItem}
          >
            {/* This div is necessary to avoid endless rerendering with Radix Checkbox implementation (https://github.com/radix-ui/primitives/issues/2291) */}
            <div
              onClick={(event) => event.stopPropagation()}
              style={{ pointerEvents: "none" }}
            >
              <Checkbox checked={isValueSelected} as="div" />
            </div>
            {Icon && (
              <div
                className={clsx(
                  "mx-2 size-6",
                  shouldDisableItem && "grayscale",
                )}
              >
                {Icon}
              </div>
            )}
            <p
              className={clsx(
                "text-paragraph-300-regular",
                isValueSelected && "text-paragraph-300-medium text-blue-600 ",
              )}
            >
              {label}
            </p>
          </ToggleGroup.Item>
        );
      })}
    </ToggleGroup.Root>
  );
};

export default PillToggleGroup;
