import useSearchParam, { SearchParam } from "@hooks/useSearchParam";
import { Path } from "@util/paths";
import safeRedirect from "@util/safeRedirect";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

const useSignupCompleteRedirect = (signupComplete: boolean | undefined) => {
  const history = useHistory();
  const location = useLocation();
  const redirectUrl = useSearchParam(SearchParam.RedirectUrl);

  const [hasRedirected, setHasRedirected] = useState(false);

  // Store initial signup complete state to react to signup completion
  const initialSignupComplete = useRef(signupComplete);
  useEffect(() => {
    if (initialSignupComplete.current == null) {
      initialSignupComplete.current = signupComplete;
    }
  }, [signupComplete]);

  useEffect(() => {
    if (!signupComplete || hasRedirected) return;

    const defaultOrigin = window.location.origin;
    const parsedRedirectUrl = new URL(redirectUrl ?? "", defaultOrigin);

    // Absolute redirect url
    if (parsedRedirectUrl.origin !== defaultOrigin) {
      setHasRedirected(true);
      return void safeRedirect(parsedRedirectUrl.toString());
    }

    // An explicit redirect path is set
    if (
      parsedRedirectUrl.pathname !== "/" &&
      !parsedRedirectUrl.pathname.startsWith(Path.Signup)
    ) {
      setHasRedirected(true);
      return history.replace({
        pathname: parsedRedirectUrl.pathname,
        search: parsedRedirectUrl.search,
      });
    }

    // The user had already completed signup before
    if (initialSignupComplete.current !== false) return;

    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(SearchParam.RedirectUrl);

    setHasRedirected(true);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [signupComplete, redirectUrl, history, location, hasRedirected]);
};

export default useSignupCompleteRedirect;
