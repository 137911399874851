import { priceTextClassName } from "@features/signup/Pages/PaymentPage/PaymentSummaryCard";
import { PlanSelection, usePlans } from "@features/signup/Pages/PlanPage";
import {
  PlanTerm,
  SUBSCRIPTION_TIER_LABELS,
  SubscriptionTier,
} from "@util/constants";

const getTierPricePerMonth = ({
  plans,
  tier,
  term,
}: {
  plans: PlanSelection[];
  tier: SubscriptionTier;
  term: PlanTerm;
}) => {
  const selectedPlan = plans.find((plan) => plan.tier === tier);
  const isAnnualScaleTier =
    tier === SubscriptionTier.SCALE && term === PlanTerm.ANNUAL;
  const price = isAnnualScaleTier
    ? selectedPlan?.yearMonthlyPrice
    : selectedPlan?.monthlyPrice;

  return ` ${price ?? "--"}${
    tier !== SubscriptionTier.ENTERPRISE ? "/mo" : ""
  } `;
};

interface SelectedTierLabelProps {
  tier: SubscriptionTier;
  term: PlanTerm;
}

const SelectedTierLabel = ({ tier, term }: SelectedTierLabelProps) => {
  const plans = usePlans();
  const isAnnualScaleTier =
    tier === SubscriptionTier.SCALE && term === PlanTerm.ANNUAL;
  const discountedPriceText = plans.find(
    (plan) => plan.tier === tier,
  )?.monthlyPrice;
  return (
    <div className="flex">
      <p className={priceTextClassName}>
        {`${SUBSCRIPTION_TIER_LABELS[tier]} -`}&nbsp;
      </p>
      {isAnnualScaleTier && (
        <p className="text-paragraph-200-regular text-grayscale-500 line-through">
          {discountedPriceText}&nbsp;
        </p>
      )}
      <p className={priceTextClassName}>
        {getTierPricePerMonth({ tier, term, plans })}
      </p>
    </div>
  );
};

export default SelectedTierLabel;
