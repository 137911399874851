import { Flag } from "@alch/dx-entities";
import { useFlag } from "@hooks/useFlag";
import { SignupStep, SignupStepsList } from "../constants";

const useSignupStepsList = () => {
  const stripeFlag = useFlag(Flag.Stripe);

  const steps = SignupStepsList.filter(
    (step) => stripeFlag.data || step !== SignupStep.PaymentConfirmation,
  );

  return {
    data: steps,
    isPending: stripeFlag.isPending,
  };
};

export default useSignupStepsList;
