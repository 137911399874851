export const FREE_TIER_CU_LIMIT = 300000000;
export const GROWTH_TIER_CU_LIMIT = 400000000;
export const GROWTH_TIER_MONTHLY_BASE_PRICE = 49;
export const GROWTH_TIER_CU_PRICE_PER_CU = 0.0000012;

export const FREE_TIER_CUS_LABEL = FREE_TIER_CU_LIMIT.toLocaleString();
export const GROWTH_TIER_CUS_LABEL = GROWTH_TIER_CU_LIMIT.toLocaleString();

// used on SignUp app, SignupShareView.tsx
export const REINFORCED_TRANSACTIONS_COST_PER_APP = 999;

/**
 * @deprecated Use TeamPlanTier in `@alch/dx-entities`
 */
export enum SubscriptionTier {
  FREE = 0,
  GROWTH = 1,
  ENTERPRISE = 2,
  OTHER = 3,
  SCALE = 4,
  PAYG = 5,
}

export const SUBSCRIPTION_TIER_LABELS: Record<SubscriptionTier, string> = {
  [SubscriptionTier.FREE]: "Free",
  [SubscriptionTier.GROWTH]: "Growth",
  [SubscriptionTier.SCALE]: "Scale",
  [SubscriptionTier.ENTERPRISE]: "Enterprise",
  [SubscriptionTier.OTHER]: "Enterprise Trial",
  [SubscriptionTier.PAYG]: "Pay As You Go",
};

/**
 * @deprecated Use TeamPlanTerm in `@alch/dx-entities`
 */
export enum PlanTerm {
  MONTHLY = 0,
  ANNUAL = 1,
}

/**
 * @deprecated Use TeamPlanUsageCapType in `@alch/dx-entities`
 */
export enum UsageCapType {
  AUTO_SCALE = 0,
  CAPPED = 1,
}

export const COMPOSER_API_KEY = "xZF7o-Vl3z94HOqwaQtrZP06swu4_E15";
export const TRACE_COMPOSER_API_KEY = "dAnEgTX45tYXUXfNwtnXGRpJ-YiDBL7d";

export enum SignupSource {
  TWITTER = 1,
  TEAMMATE = 2,
  SEARCH_ENGINE = 3,
  TUTORIAL = 4,
  OTHER = 999,
}

export enum OnDemandActionType {
  UNSPECIFIED = 0,
  ALERT = 1,
  STOPPAGE = 2,
}

export enum OnDemandThresholdUnit {
  UNSPECIFIED = 0,
  CU = 1,
  DOLLAR = 2,
}

export enum LocalStorageKeys {
  AppListSort = "appListSort",
  AppSortOrder = "appSortOrder",
}
