import * as RadioGroup from "@radix-ui/react-radio-group";
import clsx from "clsx";
import { useCallback } from "react";
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form";

export type PillRadioItem<TValue> = {
  label: string;
  value: TValue;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

export enum PillRadioGroupVariant {
  XLarge = "XLarge",
  Large = "Large",
  Small = "Small",
}
interface PillRadioGroupProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  defaultValue?: TFieldValues[TName];
  onChange?: (newVal: TFieldValues[TName]) => void;
  items: PillRadioItem<TFieldValues[TName]>[];
  variant?: PillRadioGroupVariant;
  label?: string;
}

function PillRadioGroup<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  defaultValue,
  items,
  onChange,
  control,
  name,
  variant = PillRadioGroupVariant.Large,
  label,
}: PillRadioGroupProps<TFieldValues, TName>) {
  const {
    field: { onChange: onFieldChange, value: fieldValue },
  } = useController({
    control,
    name,
  });

  const handleValueChange = useCallback(
    (newVal: TFieldValues[TName]) => {
      onFieldChange(newVal);
      if (onChange) {
        onChange(newVal);
      }
    },
    [onChange, onFieldChange],
  );

  return (
    <div>
      {label && (
        <div>
          <label className="text-label-200">{label}</label>
          <div className="h-2" />
        </div>
      )}
      <RadioGroup.Root
        className={clsx(
          "grid gap-x-6 gap-y-3",
          variant === PillRadioGroupVariant.XLarge
            ? "grid-cols-1"
            : "grid-cols-2",
        )}
        defaultValue={String(defaultValue)}
        onValueChange={handleValueChange}
        value={fieldValue}
      >
        {items.map(({ label, value, Icon }) => (
          <RadioGroup.Item
            className={clsx(
              "flex items-center rounded-2xl border border-primary bg-primary text-primary",
              variant === PillRadioGroupVariant.Small
                ? "h-[52px] justify-center px-5 py-3"
                : "h-[76px] p-4",
              variant === PillRadioGroupVariant.XLarge
                ? "justify-center text-center text-paragraph-300-medium"
                : "text-paragraph-300-regular",
              "aria-checked:border-2 aria-checked:border-brand aria-checked:text-brand",
            )}
            key={String(value)}
            id={String(value)}
            value={String(value)}
          >
            {Icon && <Icon className="ml-2 mr-5 size-6" />}
            <span>{label}</span>
          </RadioGroup.Item>
        ))}
      </RadioGroup.Root>
    </div>
  );
}

export default PillRadioGroup;
