import { NetworkId, WebhookType } from "@alch/dx-entities";
import { Action, Reducer, combineReducers } from "redux";
import { ThunkDispatch } from "redux-thunk";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import * as http from "../http/endpoints";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import {
  Loadable,
  makeFetchThunkActionCreator,
  makeLoadingActionCreators,
  reducerForIndexedLoadables,
  reducerForLoadable,
} from "../util/loadable";
import { AppThunkAction, RootState } from "./root";

export interface WebhooksState {
  webhooks: Loadable<Webhook[]>;
  graphQLQueries: { [webhookId: string]: Loadable<WebhookGraphQLQuery> };
}

const requestGraphQLQuery = makeLoadingActionCreators<
  GetWebhookGraphQLQueryParams,
  WebhookGraphQLQuery
>("REQUEST_GRAPHQL_QUERY_REQUESTS");

export const fetchGraphQLQueries = makeFetchThunkActionCreator<
  GetWebhookGraphQLQueryParams,
  WebhookGraphQLQuery
>({
  actionCreators: requestGraphQLQuery,
  getFromState: (state, params) =>
    state.webhooks.graphQLQueries[params.webhook_id] || Loadable.unloaded(),
  fetchResult: http.getWebhookGraphQLQuery,
});

export interface Webhook {
  id: string;
  name?: string;
  app_id: string;
  version: WebhookVersion;
  network: NetworkId;
  webhook_type: WebhookType;
  webhook_url: string;
  signing_key: string;
  is_active: boolean;
  addresses?: string[];
  nft_filters?: NftFilter[];
  nft_metadata_filters?: NftFilter[];
  gas_price_low?: number;
  gas_price_high?: number;
  gas_price_type?: GasPriceType;
}

export interface NftFilter {
  contract_address?: string;
  token_id?: string;
}

export type WebhookExternal = Omit<Webhook, "webhook_type"> & {
  webhook_type: keyof typeof WebhookType;
};

export enum GasPriceType {
  SAFE_LOW = 0,
  AVERAGE = 1,
  FAST = 2,
  FASTEST = 3,
}

export const GAS_PRICE_TYPE_NAME: { [gasPriceType in GasPriceType]: string } = {
  [GasPriceType.SAFE_LOW]: "Safe Low",
  [GasPriceType.AVERAGE]: "Average",
  [GasPriceType.FAST]: "Fast",
  [GasPriceType.FASTEST]: "Fastest",
};

export enum WebhookVersion {
  V1 = "V1",
  V2 = "V2",
}

export interface CreateWebhookParams {
  webhook_type: keyof typeof WebhookType;
  webhook_url: string;
  network: NetworkId;
  app_id?: string;
  name?: string;
  addresses?: string[];
  graphql_query?: string | { query: string; skip_empty_messages?: boolean };
  nft_filters?: NftFilter[];
  nft_metadata_filters?: NftFilter[];
  gas_price_low?: number;
  gas_price_high?: number;
  gas_price_type?: GasPriceType;
}

export interface TestWebhookParams {
  version: WebhookVersion;
  network: NetworkId;
  app_id: string;
  webhook_type: WebhookType;
  webhook_url: string;
  addresses?: string[];
  graphql_query?: string;
  nft_filters?: NftFilter[];
  nft_metadata_filters?: NftFilter[];
  gas_price_low?: number;
  gas_price_high?: number;
  gas_price_type?: GasPriceType;
  webhook_id?: string;
}

export interface DeleteWebhookParams {
  webhook_id: string;
}

export interface GetWebhookGraphQLQueryParams {
  webhook_id: string;
}

export interface WebhookGraphQLQuery {
  webhook_id: string;
  graphql_query: string;
}

export interface UpdateWebhookActiveParams {
  webhook_id: string;
  is_active: boolean;
}

export interface UpdateWebhookNameParams {
  webhook_id: string;
  name: string;
}

export interface UpdateWebhookAddressParams {
  webhook_id: string;
  addresses_to_add: string[];
  addresses_to_remove: string[];
}

export interface UpdateWebhookNftFiltersParams {
  webhook_id: string;
  nft_filters_to_add: NftFilter[];
  nft_filters_to_remove: NftFilter[];
}

export interface UpdateWebhookNftMetadataFiltersParams {
  webhook_id: string;
  nft_metadata_filters_to_add: NftFilter[];
  nft_metadata_filters_to_remove: NftFilter[];
}

const requestWebhooks = makeLoadingActionCreators<void, Webhook[]>(
  "REQUEST_WEBHOOKS",
);

export const fetchWebhooks = makeFetchThunkActionCreator<void, Webhook[]>({
  actionCreators: requestWebhooks,
  getFromState: (state) => state.webhooks.webhooks,
  fetchResult: http.getTeamWebhooks,
});

// TODO(dphil): This is weaksauce handling of async operations while creating or
// updating webhooks. Find a way to display errors and loading state.

export function createWebhook(
  params: CreateWebhookParams,
): AppThunkAction<Promise<void>> {
  return async (dispatch) => {
    await http.createWebhook(params);
    void dispatch(fetchWebhooks());
  };
}

export function updateWebhookActive(
  params: UpdateWebhookActiveParams,
): AppThunkAction<Promise<void>> {
  return async (dispatch) => {
    await http.updateWebhookActive(params);
    void dispatch(fetchWebhooks());
  };
}

export function updateWebhookName(
  params: UpdateWebhookNameParams,
): AppThunkAction<Promise<void>> {
  return async (dispatch) => {
    await http.updateWebhookName(params);
    void dispatch(fetchWebhooks());
  };
}

export function updateWebhookAddress(
  params: UpdateWebhookAddressParams,
): AppThunkAction<Promise<void>> {
  return async (dispatch) => {
    await http.updateWebhookAddress(params);
    void dispatch(fetchWebhooks());
  };
}

export function updateWebhookNftFilters(
  params: UpdateWebhookNftFiltersParams,
): AppThunkAction<Promise<void>> {
  return async (dispatch) => {
    await http.updateWebhookNftFilters(params);
    void dispatch(fetchWebhooks());
  };
}

export function updateWebhookNftMetadataFilters(
  params: UpdateWebhookNftMetadataFiltersParams,
): AppThunkAction<Promise<void>> {
  return async (dispatch) => {
    await http.updateWebhookNftMetadataFilters(params);
    void dispatch(fetchWebhooks());
  };
}

export function deleteWebhook(
  params: DeleteWebhookParams,
): AppThunkAction<Promise<void>> {
  return async (dispatch) => {
    await http.deleteWebhook(params);
    void dispatch(fetchWebhooks());
  };
}

export const webhooksReducer: Reducer<WebhooksState> = combineReducers({
  webhooks: reducerForLoadable(requestWebhooks),
  graphQLQueries: reducerForIndexedLoadables(
    requestGraphQLQuery,
    (params) => params.webhook_id,
  ),
});

export type WebhookDispatch = ThunkDispatch<RootState, object, Action>;
