import { AnnouncementBanner } from "@alch/ui";
import { useIsPAYGTierActive } from "@hooks/useIsPAYGTierActive";
import { SubscriptionTier, UsageCapType } from "@util/constants.ts";
import {
  ExpirableEvent,
  useExpirableEventStorage,
} from "@util/localStorage.ts";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/root";
import { getFirstDayOfNextMonth } from "../common/DateUtils";
import { ViewAsPreservingLink } from "../common/ViewAsPreservingLink";

const useUsagePercent = () => {
  const user = useSelector((state: RootState) => state.users.currentUser.value);
  const activePlan = useSelector(
    (state: RootState) => state.team.activePlan.value,
  );
  const fcuUsageStats = useSelector(
    (state: RootState) => state.team.fcuUsageStats.value,
  );

  if (
    !(
      user &&
      user.is_billing_admin &&
      fcuUsageStats &&
      activePlan &&
      activePlan.usage_cap_type === UsageCapType.CAPPED
    )
  ) {
    return null;
  }

  const percentUsed = Math.round(
    (100 * fcuUsageStats.used) / fcuUsageStats.limit,
  );

  return percentUsed;
};

const CapacityLimitBanner = () => {
  const percentUsed = useUsagePercent();

  const [isEventSet, setEvent] = useExpirableEventStorage(
    percentUsed == null || percentUsed < 100
      ? ExpirableEvent.USAGE_WARNING_BANNER_DISMISSED
      : ExpirableEvent.USAGE_DANGER_BANNER_DISMISSED,
    getFirstDayOfNextMonth().getTime() - new Date().getTime(),
  );

  const isPAYGTierActive = useIsPAYGTierActive();

  const activePlan = useSelector(
    (state: RootState) => state.team.activePlan.value,
  );

  if (
    isEventSet ||
    percentUsed == null ||
    percentUsed < 80 ||
    isPAYGTierActive.isLoading
  ) {
    return null;
  }

  return (
    <AnnouncementBanner
      intent={percentUsed >= 100 ? "error" : "warning"}
      onDismiss={setEvent}
    >
      You have used {percentUsed}% of your compute units.{" "}
      <AnnouncementBanner.Link as={ViewAsPreservingLink} to="/settings/billing">
        {activePlan?.tier === SubscriptionTier.FREE
          ? `Upgrade to ${!isPAYGTierActive.data ? "Growth" : "Pay As You Go"}`
          : "Turn on autoscale."}
      </AnnouncementBanner.Link>
    </AnnouncementBanner>
  );
};

export default CapacityLimitBanner;
