import { SetupStage } from "@alch/dx-entities";
import pureAssign from "pure-assign";
import { combineReducers, Reducer } from "redux";
import actionCreatorFactory from "typescript-fsa";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import * as http from "../http/endpoints";
import { PlanTerm, SubscriptionTier, UsageCapType } from "../util/constants";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import {
  Loadable,
  makeFetchThunkActionCreator,
  makeLoadingActionCreators,
  reducerBuilderForLoadable,
  reducerForLoadable,
} from "../util/loadable";
import { AppThunkAction } from "./root";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import { UserSettings } from "./users";

const actionCreator = actionCreatorFactory();

export interface TeamState {
  team: Loadable<Team>;
  fcuUsageStats: Loadable<FcuUsageStats>;
  updatingRateLimitSettings: Loadable<TeamAndUserSettings>;
  planPreference: Loadable<TeamPlan>;
  updatingPlanPreference: Loadable<TeamPlan>;
  activePlan: Loadable<TeamMonthlyPlan>;
  referralMonthlyBonus: Loadable<ReferralMonthlyBonus>;
  referralSocialBonuses: Loadable<ReferralSocialBonuses>;
  earningSocialBonus: Loadable<void>;
}

// TODO: look into creating a composite interface for Team
type Flags =
  | "enable_flow_in_dash"
  | "enable_polygonzkevm_in_dash"
  | "allow_data_platform"
  | "allow_subgraphs";

// See OnboardingProjectTypePreference enums.py
export enum OnboardingProjectTypePreference {
  Analytics = "analytics",
  DAO = "daos",
  Defi = "defi",
  Learning = "learning",
  NFT = "nfts",
  NotADeveloper = "not a developer",
  Other = "",
  Gaming = "gaming",
  Wallet = "wallet",
  Security = "security",
  Social = "social",
  Marketplace = "marketplace",
  InfraAndTooling = "infraAndTooling",
}

export enum ReferredByAffiliate {
  ChatWeb3 = "ChatWeb3",
}

export interface Team {
  id: number;
  name: string;
  time_created: number;
  expiry_time: number | null;
  setup_stage: SetupStage;
  app_limit: number;
  active_webhook_limit: number;
  rate_limit_alert_acknowledged: boolean;
  support_channel_url: string;
  free_trial_start_time: number;
  free_trial_duration_days: number;
  referral_token: string;
  auth_token: string;
  flags: Partial<Record<Flags, boolean>>;
  referred_by?: string;
  referred_by_affiliate?: string;
  onboarding_project_type_preference?: OnboardingProjectTypePreference;
}

export interface TeamPlan {
  tier: SubscriptionTier;
  plan_term: PlanTerm;
  monthly_cu: number;
  monthly_cu_bonus: number;
  usage_cap_type: UsageCapType;
  contract_start_date: string;
  contract_end_date: string;
  time_changed_tier?: string;
}

export interface UpdatePlanPreferenceParams {
  tier: SubscriptionTier;
  usage_cap_type?: UsageCapType;
  plan_term?: PlanTerm;
  annual_monthly_price?: number; // only for scale annual
}

export interface RequestUpgradeParams {
  app_id: string;
}

export interface TeamMonthlyPlan {
  tier: SubscriptionTier;
  plan_term: PlanTerm;
  monthly_base_price: number;
  overage_price_per_cu: string;
  monthly_cu: number;
  monthly_cu_bonus: number;
  usage_cap_type: UsageCapType;
  start_date: string;
  end_date: string;
  contract_start_date: string;
  contract_end_date: string;
  active_webhook_limit: number;
}

export interface FcuUsageStats {
  limit: number;
  used: number;
  overage_price_per_cu: number;
}

export interface UpdateSetupStageParams {
  setup_stage: SetupStage;
}

export interface UpdateRateLimitSettingsParams {
  rate_limit_alert_acknowledged: boolean;
}

export interface CreatePaymentProfileParams {
  token: string;
}

export interface TeamAndUserSettings {
  team: Team;
  user_settings: UserSettings;
}

export type ReferralSocialMedium =
  | "email"
  | "twitter"
  | "linkedin"
  | "discord"
  | "telegram";

export interface ReferralMonthlyBonus {
  bonus: number;
}

export interface ReferralSocialBonuses {
  mediums: ReferralSocialMedium[];
}

export interface EarnSocialBonusParams {
  medium: ReferralSocialMedium;
  emails?: string[];
}

export const requestTeam = makeLoadingActionCreators<void, Team>(
  "REQUEST_TEAM",
);

const requestTeamPlan = makeLoadingActionCreators<void, TeamPlan>(
  "REQUEST_TEAM_PLAN",
);

const requestUpdateTeamPlan = makeLoadingActionCreators<
  UpdatePlanPreferenceParams,
  TeamPlan
>("REQUEST_UPDATE_TEAM_PLAN");

export const resetUpdateTeamPlan = actionCreator("RESET_UPDATE_TEAM_PLAN");

const requestTeamMonthlyPlan = makeLoadingActionCreators<void, TeamMonthlyPlan>(
  "REQUEST_TEAM_MONTH_PLAN",
);

const requestFcuUsageStats = makeLoadingActionCreators<void, FcuUsageStats>(
  "REQUEST_FCU_USAGE_STATS",
);

export const setSetupStage = actionCreator<SetupStage>("SET_SETUP_STAGE");
export const setTeamName = actionCreator<string>("SET_TEAM_NAME");

export const requestUpdateRateLimitSettings = makeLoadingActionCreators<
  UpdateRateLimitSettingsParams,
  TeamAndUserSettings
>("REQUEST_UPDATE_RATE_LIMIT_SETTINGS");
export const resetUpdateRateLimitSettings = actionCreator(
  "RESET_UPDATE_RATE_LIMIT_SETTINGS",
);

export const requestReferralMonthlyBonus = makeLoadingActionCreators<
  void,
  ReferralMonthlyBonus
>("REQUEST_REFERRAL_MONTHLY_BONUS");

export const requestReferralSocialBonuses = makeLoadingActionCreators<
  void,
  ReferralSocialBonuses
>("REQUEST_REFERRAL_SOCIAL_BONUSES");

export const requestEarnSocialBonus = makeLoadingActionCreators<
  EarnSocialBonusParams,
  void
>("REQUEST_EARN_SOCIAL_BONUS");

export const resetUpdateTeamName = actionCreator("RESET_UPDATE_TEAM_NAME");

export const fetchTeam = makeFetchThunkActionCreator<void, Team>({
  actionCreators: requestTeam,
  getFromState: (state) => state.team.team,
  fetchResult: http.getTeam,
});

export const updatePlanPreference = makeFetchThunkActionCreator<
  UpdatePlanPreferenceParams,
  TeamPlan
>({
  actionCreators: requestUpdateTeamPlan,
  getFromState: (state) => state.team.updatingPlanPreference,
  fetchResult: (params) => http.putPlanPreference(params),
});

export const fetchPlanPreference = makeFetchThunkActionCreator<void, TeamPlan>({
  actionCreators: requestTeamPlan,
  getFromState: (state) => state.team.planPreference,
  fetchResult: http.getTeamPlan,
});

export const fetchActivePlan = makeFetchThunkActionCreator<
  void,
  TeamMonthlyPlan
>({
  actionCreators: requestTeamMonthlyPlan,
  getFromState: (state) => state.team.activePlan,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- FIXME
  fetchResult: (_) => http.getTeamMonthlyPlan({}),
});

export const fetchFcuUsageStats = makeFetchThunkActionCreator<
  void,
  FcuUsageStats
>({
  actionCreators: requestFcuUsageStats,
  getFromState: (state) => state.team.fcuUsageStats,
  fetchResult: http.getTeamFcuUsageStats,
});

export const updateRateLimitSettings = makeFetchThunkActionCreator<
  UpdateRateLimitSettingsParams,
  TeamAndUserSettings
>({
  actionCreators: requestUpdateRateLimitSettings,
  getFromState: (state) => state.team.updatingRateLimitSettings,
  fetchResult: http.updateRateLimitSettings,
});

export function updateSetupStage(setupStage: SetupStage): AppThunkAction<void> {
  return (dispatch) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- FIXME
    http.updateSetupStage({ setup_stage: setupStage });
    dispatch(setSetupStage(setupStage));
  };
}

export const fetchReferralMonthlyBonus = makeFetchThunkActionCreator<
  void,
  ReferralMonthlyBonus
>({
  actionCreators: requestReferralMonthlyBonus,
  getFromState: (state) => state.team.referralMonthlyBonus,
  fetchResult: http.getReferralMonthlyBonus,
});

export const fetchReferralSocialBonuses = makeFetchThunkActionCreator<
  void,
  ReferralSocialBonuses
>({
  actionCreators: requestReferralSocialBonuses,
  getFromState: (state) => state.team.referralSocialBonuses,
  fetchResult: http.getReferralSocialBonuses,
});

export const earnReferralSocialBonus = makeFetchThunkActionCreator<
  EarnSocialBonusParams,
  void
>({
  actionCreators: requestEarnSocialBonus,
  getFromState: (state) => state.team.earningSocialBonus,
  fetchResult: http.earnReferralSocialBonus,
});

export const teamReducer: Reducer<TeamState> = combineReducers({
  team: reducerBuilderForLoadable(requestTeam)
    .case(setSetupStage, (state, setupStage) =>
      Loadable.map(state, (team) =>
        pureAssign(team, { setup_stage: setupStage }),
      ),
    )
    .case(
      requestUpdateRateLimitSettings.done,
      (state, { result: { value, updateTime } }) =>
        Loadable.setDone(state, { updateTime, value: value.team }),
    )
    .case(setTeamName, (state, name) =>
      Loadable.map(state, (team) => pureAssign(team, { name })),
    )
    .build(),
  planPreference: reducerBuilderForLoadable(requestTeamPlan).case(
    requestUpdateTeamPlan.done,
    (state, { result }) => Loadable.setDone(state, result),
  ),
  updatingPlanPreference: reducerBuilderForLoadable(requestUpdateTeamPlan)
    .case(resetUpdateTeamPlan, () => Loadable.unloaded())
    .build(),
  activePlan: reducerForLoadable(requestTeamMonthlyPlan),
  fcuUsageStats: reducerForLoadable(requestFcuUsageStats),
  updatingRateLimitSettings: reducerBuilderForLoadable(
    requestUpdateRateLimitSettings,
  )
    .case(resetUpdateRateLimitSettings, () => Loadable.unloaded())
    .build(),
  referralMonthlyBonus: reducerBuilderForLoadable(
    requestReferralMonthlyBonus,
  ).build(),
  referralSocialBonuses: reducerBuilderForLoadable(requestReferralSocialBonuses)
    .case(requestEarnSocialBonus.done, (state, { params: { params } }) =>
      Loadable.map(state, ({ mediums }) => ({
        mediums: Array.from(new Set([...mediums, params.medium])),
      })),
    )
    .build(),
  earningSocialBonus: reducerBuilderForLoadable(requestEarnSocialBonus)
    .case(requestEarnSocialBonus.done, () => Loadable.unloaded())
    .build(),
});
