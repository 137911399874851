import {
  onDemandCUsToUSD,
  TieredPricingConfig,
} from "@features/usage-page/overview-usage-tab/hooks/useUsagePricingInfo";
import { PricingTier, TIER_PRICING } from "@util/payment/pricingConfig.ts";

/**
 * Calculate cost for a base, unit cost per million, and included units.
 * Does not distinguish between prepaid and on-demand.
 */
const tierCost = (
  usedUnits: number, // Should be a multiple of 1M
  baseCost: number,
  unitCPM: number | TieredPricingConfig[],
  includedUnits: number,
): number => {
  const overrageUnits = Math.max(0, usedUnits - includedUnits);
  const overrageCost =
    typeof unitCPM === "number"
      ? (overrageUnits * unitCPM) / 1_000_000
      : onDemandCUsToUSD(overrageUnits, unitCPM);
  return Math.round(baseCost + overrageCost);
};

/**
 * Estimated monthly cost in USD per tier for a given usage.  Assume customer prepaid for
 * additional usage if the plan allowed it.
 *
 * 0 for plans that have special rules, like Enterprise.
 */
export default function estimatedMonthlyTierCosts(
  units: number,
): Record<PricingTier, number> {
  return {
    [PricingTier.FREE]: 0,
    [PricingTier.GROWTH]: tierCost(
      units,
      TIER_PRICING[PricingTier.GROWTH].baseCost,
      TIER_PRICING[PricingTier.GROWTH].onDemandCPM, // Assume on-demand
      TIER_PRICING[PricingTier.GROWTH].includedUnits,
    ),
    [PricingTier.SCALE_MONTHLY]: tierCost(
      units,
      TIER_PRICING[PricingTier.SCALE_MONTHLY].baseCost,
      TIER_PRICING[PricingTier.SCALE_MONTHLY].onDemandCPM, // Assume on-demand
      TIER_PRICING[PricingTier.SCALE_MONTHLY].includedUnits,
    ),
    [PricingTier.SCALE_ANNUAL]: tierCost(
      units,
      TIER_PRICING[PricingTier.SCALE_ANNUAL].baseCost,
      TIER_PRICING[PricingTier.SCALE_ANNUAL].prepaidCPM, // Assume prepaid
      TIER_PRICING[PricingTier.SCALE_ANNUAL].includedUnits,
    ),
    [PricingTier.PAYG]: tierCost(
      units,
      TIER_PRICING[PricingTier.PAYG].baseCost,
      TIER_PRICING[PricingTier.PAYG].onDemandCPM,
      TIER_PRICING[PricingTier.PAYG].includedUnits,
    ),
    [PricingTier.ENTERPRISE]: 0,
    [PricingTier.OTHER]: 0,
  };
}
