import { Flag } from "@alch/dx-entities";
import { PricingTier, TIER_PRICING } from "@util/payment/pricingConfig";
import { useFlag } from "./useFlag";

export const useIsPAYGTierActive = () => {
  const flag = useFlag(Flag.PAYGTier);

  // An extremely hacky way of changing includedUnits on the Free tier without
  // having to refactor TIER_PRICING into a hook, as this is temporary for PAYG launch
  if (flag.data) {
    TIER_PRICING[PricingTier.FREE].includedUnits = 100_000_000;
  }

  return flag;
};
