import { PricingPickerTier } from "@features/payment/PricingPicker/PricingPicker";
import { PlanTerm, SubscriptionTier } from "../constants";

export const planTierToPricingTier = (
  planTier: SubscriptionTier,
  planTerm: PlanTerm,
): PricingPickerTier => {
  switch (planTier) {
    case SubscriptionTier.FREE:
      return PricingPickerTier.FREE;
    case SubscriptionTier.GROWTH:
      return PricingPickerTier.GROWTH;
    case SubscriptionTier.ENTERPRISE:
      return PricingPickerTier.ENTERPRISE;
    case SubscriptionTier.OTHER:
      // Other subscription is used for enterprise trial
      return PricingPickerTier.ENTERPRISE;
    case SubscriptionTier.SCALE:
      return planTerm === PlanTerm.MONTHLY
        ? PricingPickerTier.SCALE_MONTHLY
        : PricingPickerTier.SCALE_ANNUAL;
    case SubscriptionTier.PAYG:
      return PricingPickerTier.PAYG;
    default:
      throw new Error(`PricingPicker passed an invalid tier'`);
  }
};

export const pricingTierToPlanTier = (
  tier: PricingPickerTier,
): [SubscriptionTier, PlanTerm] => {
  switch (tier) {
    case PricingPickerTier.FREE:
      return [SubscriptionTier.FREE, PlanTerm.MONTHLY];
    case PricingPickerTier.GROWTH:
      return [SubscriptionTier.GROWTH, PlanTerm.MONTHLY];
    case PricingPickerTier.ENTERPRISE:
      return [SubscriptionTier.ENTERPRISE, PlanTerm.MONTHLY];
    case PricingPickerTier.SCALE_MONTHLY:
      return [SubscriptionTier.SCALE, PlanTerm.MONTHLY];
    case PricingPickerTier.SCALE_ANNUAL:
      return [SubscriptionTier.SCALE, PlanTerm.ANNUAL];
    case PricingPickerTier.OTHER:
      return [SubscriptionTier.ENTERPRISE, PlanTerm.MONTHLY];
    case PricingPickerTier.PAYG:
      return [SubscriptionTier.PAYG, PlanTerm.MONTHLY];
  }
};
