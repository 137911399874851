import { InlineAlert } from "@alch/ui";
import { formatWholeAndFractionalDollars } from "@features/payment/Checkout/lib/numberFormatters";
import PlanTermRadioGroup from "@features/signup/Pages/PaymentPage/PlanTermRadioGroup";
import SelectedTierLabel from "@features/signup/Pages/PlanPage/SelectedTierLabel";
import Label from "@features/signup/components/Label";
import { PlanTerm, SubscriptionTier } from "@util/constants";
import { PricingTier, TIER_PRICING } from "@util/payment/pricingConfig";
import clsx from "clsx";
import usePlanCostPreview from "../../../../react-query/queries/usePlanCostPreview";

export const priceTextClassName = clsx(
  "text-paragraph-200-medium text-grayscale-950",
);

interface PaymentSummaryRowProps {
  label: string;
  children: React.ReactNode;
  showProratedLabel?: boolean;
}

const PaymentSummaryRow = ({
  label,
  children,
  showProratedLabel,
}: PaymentSummaryRowProps) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex ">
        <p className="text-paragraph-200-regular text-grayscale-700">{label}</p>
        {showProratedLabel && (
          <Label text="Prorated" className="ml-2 bg-grayscale-100" />
        )}
      </div>
      {children}
    </div>
  );
};

interface PaymentSummaryCardProps {
  tier: SubscriptionTier;
  term: PlanTerm;
}

const ANNUAL_SCALE_TIER_PRICE =
  TIER_PRICING[PricingTier.SCALE_ANNUAL].baseCost * 12;
const MONTHLY_SCALE_TIER_PRICE =
  TIER_PRICING[PricingTier.SCALE_MONTHLY].baseCost * 12;

const PaymentSummaryCard = ({ tier, term }: PaymentSummaryCardProps) => {
  const { data, isLoading } = usePlanCostPreview(tier);

  if (isLoading) return null;

  const isAnnualScaleTier =
    tier === SubscriptionTier.SCALE && term === PlanTerm.ANNUAL;

  // Chargify's API doesn't provide us with a way to get prorated Scale Annual price so just show the user the raw Scale Yearly price
  const price = formatWholeAndFractionalDollars(
    isAnnualScaleTier
      ? ANNUAL_SCALE_TIER_PRICE
      : (data?.total_in_cents ?? 0) / 100,
  );

  return (
    <div className="grid grid-cols-1 gap-y-1 rounded-2xl border border-grayscale-200 bg-white p-6">
      <PaymentSummaryRow label="Selected tier">
        <SelectedTierLabel tier={tier} term={term} />
      </PaymentSummaryRow>
      {tier === SubscriptionTier.SCALE && (
        <PaymentSummaryRow label="Billing type">
          <PlanTermRadioGroup />
        </PaymentSummaryRow>
      )}

      <PaymentSummaryRow
        label="Due today"
        showProratedLabel={
          [SubscriptionTier.GROWTH, SubscriptionTier.SCALE].includes(tier) &&
          !isAnnualScaleTier
        }
      >
        <div className="flex">
          {isAnnualScaleTier && (
            <p className="text-paragraph-200-regular text-grayscale-500 line-through">
              {formatWholeAndFractionalDollars(MONTHLY_SCALE_TIER_PRICE)}
              &nbsp;
            </p>
          )}
          <p className={priceTextClassName}>{price}</p>
        </div>
      </PaymentSummaryRow>

      {[SubscriptionTier.FREE, SubscriptionTier.ENTERPRISE].includes(tier) && (
        <InlineAlert intent="info" className="mt-2">
          {tier === SubscriptionTier.FREE &&
            `We won't charge you unless you upgrade to a paid tier.`}
          {tier === SubscriptionTier.ENTERPRISE &&
            `Our team will contact you to discuss a custom plan.`}
        </InlineAlert>
      )}
    </div>
  );
};

export default PaymentSummaryCard;
