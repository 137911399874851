import { SubscriptionTier } from "@util/constants";
import { useIsPAYGTierActive } from "./useIsPAYGTierActive";

const LEGACY_PLANS = [
  SubscriptionTier.FREE,
  SubscriptionTier.GROWTH,
  SubscriptionTier.SCALE,
  SubscriptionTier.ENTERPRISE,
];

const PAYG_PLANS = [
  SubscriptionTier.FREE,
  SubscriptionTier.PAYG,
  SubscriptionTier.ENTERPRISE,
];

const useAvailablePlanTiers = () => {
  const isPAYGTierActive = useIsPAYGTierActive();

  if (isPAYGTierActive.isLoading || isPAYGTierActive.error)
    return { ...isPAYGTierActive, data: undefined };

  return {
    ...isPAYGTierActive,
    data: isPAYGTierActive.data ? PAYG_PLANS : LEGACY_PLANS,
  };
};

export default useAvailablePlanTiers;
