import useActivePlan from "@features/profile/PlanPage/hooks/useActivePlan";
import { SubscriptionTier } from "@util/constants";
import { orderBy } from "lodash";
import { TeamMonthlyPlan } from "../../../../redux/team";

export function useUsagePricingInfo(teamId?: number) {
  return useActivePlan({
    select: (data) => getUsagePricingInfo(data, teamId),
  });
}

export interface UsagePricingInfo {
  basePrice: number;
  overageTieredPricingConfigs: TieredPricingConfig[];
}

function getUsagePricingInfo(
  activePlan: Pick<
    TeamMonthlyPlan,
    "overage_price_per_cu" | "tier" | "monthly_base_price"
  >,
  teamId?: number,
): UsagePricingInfo {
  const teamTieredCuPricingOverride = teamId
    ? TEAM_ID_TIERED_CU_PRICING_OVERRIDES[teamId]
    : null;
  const planTieredCuPricing = TIERED_CU_PRICING[activePlan.tier];
  const defaultTieredCuPricing = [
    { min: 1, max: Infinity, price: +activePlan.overage_price_per_cu },
  ];

  return {
    basePrice: getBasePrice(activePlan.tier, activePlan.monthly_base_price),
    overageTieredPricingConfigs:
      teamTieredCuPricingOverride ??
      planTieredCuPricing ??
      defaultTieredCuPricing,
  };
}

/**
 * Converts the given number of on-demand CUs to USD based on the tiered pricing config.
 */
export function onDemandCUsToUSD(
  onDemandCUs: number,
  tieredPricingConfigs: TieredPricingConfig[],
) {
  let totalPrice = 0;
  let unpricedCUs = onDemandCUs;

  const orderedConfigs = orderBy(tieredPricingConfigs, "min", "desc");
  for (const { min, max, price } of orderedConfigs) {
    if (unpricedCUs > min && unpricedCUs <= max) {
      totalPrice += (unpricedCUs - min) * price;
      unpricedCUs = min;
    }
  }

  return totalPrice;
}

/**
 * Returns the base price for a given subscription tier.
 */
function getBasePrice(
  tier: SubscriptionTier,
  monthlyBasePrice: number,
): number {
  switch (tier) {
    case SubscriptionTier.GROWTH:
      return 49;
    case SubscriptionTier.ENTERPRISE:
    case SubscriptionTier.SCALE:
      return monthlyBasePrice;
    case SubscriptionTier.PAYG:
    default:
      return 0;
  }
}

/**
 * Tiered pricing configuration for a specific range of on-demand CUs.
 */
export interface TieredPricingConfig {
  min: number;
  max: number;
  price: number;
}

/**
 * Tiered pricing for on-demand CUs per active plan tier.
 */
export const TIERED_CU_PRICING: Partial<
  Record<SubscriptionTier, TieredPricingConfig[]>
> = {
  [SubscriptionTier.GROWTH]: [{ min: 0, max: Infinity, price: 0.0000012 }],
  [SubscriptionTier.SCALE]: [{ min: 0, max: Infinity, price: 0.000001 }],
  [SubscriptionTier.PAYG]: [
    { min: 0, max: 300_000_000, price: 0.45 * 1e-6 },
    { min: 300_000_000, max: Infinity, price: 0.4 * 1e-6 },
  ],
};

/**
 * Configures an override for tiered pricing for a specific team id.
 */
const TEAM_ID_TIERED_CU_PRICING_OVERRIDES: Record<
  number,
  TieredPricingConfig[]
> = {
  2: [
    // OpenSea
    { min: 0, max: 25_000_000_000, price: 0.00000063 },
    { min: 25_000_000_000, max: Infinity, price: 0.000001 },
  ],
};
